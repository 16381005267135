/**
 * @returns Headers to let Varnish know this request can be cached.
 */
const getCacheableContext = () => ({
  headers: {
    'x-cache': 1,
  },
})

export default getCacheableContext
