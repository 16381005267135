import { defaultDataIdFromObject, IdGetter, StoreObject } from '@apollo/client'

import {
  EntityUrl,
  SelectedConfigurableOption,
} from '@emico/graphql-schema-types'

/**
 * Generate a unique ID for the Apollo cache.
 * @param object
 * @returns
 */
const dataIdFromObject: IdGetter = (object) => {
  switch (object.__typename) {
    case 'Customer':
    case 'Cart':
      return `${object.__typename}`
    case 'EntityUrl': {
      const entityUrl = object as unknown as EntityUrl

      return `${object.__typename}:${entityUrl.type}:${entityUrl.relativeUrl}`
    }
    case 'StoreConfig':
      return 'StoreConfig'
    case 'SelectedConfigurableOption': {
      const option = object as unknown as SelectedConfigurableOption

      return `${option.__typename}:${option.configurableProductOptionUid}:${option.configurableProductOptionValueUid}`
    }
    default:
      return defaultDataIdFromObject(object as Readonly<StoreObject>)
  }
}

export default dataIdFromObject
