import borders from './borders'
import colors from './colors'
import typography from './typography'
import spacing from './spacing'
import sizes from './sizes'
import transitions from './transitions'
import animations from './animations'
import zIndices from './zIndices'
import shadow from './shadow'
import stylingSnippets from './stylingSnippets'

const theme = {
  colors,
  ...borders,
  ...spacing,
  ...sizes,
  ...typography,
  zIndices,
  transition: transitions,
  animation: animations,
  stylingSnippet: stylingSnippets,
  shadow,
}

export default theme
