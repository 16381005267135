import defaultsToVars, { defaultsToCss } from '../lib/cssVarsUtils'

const colorPrefix = 'color'

const paletteDefaults = {
  white: '#ffffff',
  black: '#000000',
  grayExtraDark: '#282b2c',
  grayDark: '#404547',
  grayMiddle: '#D5D5D5',
  grayLight: '#F4F4F4',
  grayBrown: '#818181',
  redTransparent: '#FFF3F2',
  red: '#D71920',
  redDark: '#b7151b',
  blue: '#003189',
  pink: '#FAE7E8',
  orange: '#FF784A',
  yellow: '#F59E0B',
  green: '#22C55E',
  greenMiddle: '#4EAC3A',
  greenDark: '#388627',
}

export const palette = defaultsToVars(paletteDefaults, colorPrefix)

const defaults = {
  primary: palette.red,
  primaryHover: palette.redDark,
  secondary: palette.grayDark,
  secondaryHover: palette.grayExtraDark,
  primaryGreen: palette.greenMiddle,
  primaryGreenHover: palette.greenDark,
  onPrimary: palette.white,
  onSecondary: palette.white,

  // Semantics
  success: palette.green,
  info: palette.blue,
  warning: palette.orange,
  error: palette.red,

  text: palette.grayDark,
  textLight: palette.white,
  textMuted: palette.grayMiddle,
  border: palette.grayMiddle,
  borderDark: palette.grayDark,
  background: palette.white,
  backgroundDark: palette.grayDark,
  backgroundLight: palette.grayLight,
  onBackground: palette.black,

  shadow: 'rgba(63, 63, 63, 0.14)',
}

export const colorsCss = [
  ...defaultsToCss(paletteDefaults, colorPrefix),
  ...defaultsToCss(defaults, colorPrefix),
]

export const semantics = defaultsToVars(defaults, colorPrefix)

const colors = {
  ...palette,
  ...semantics,
}

export default colors
