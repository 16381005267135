import { ApolloClient, gql, TypedDocumentNode, useQuery } from '@apollo/client'

import getCacheableContext from '../apollo/getCacheableContext'
import { StoreConfigQuery } from './storeConfig.generated'

export const storeConfigFragment = gql`
  fragment StoreConfigFragment on StoreConfig {
    websiteId
    # storeCountryId
    locale
    storeName
    id
    rootCategoryId
    code
    cmsHomePage
    cmsNoRoute
    gtmContainerIds
    defaultDisplayCurrencyCode
    requiredCharacterClassesNumber
    minimumPasswordLength
    defaultCountryId
    rootCategoryUid
    baseLinkUrl
    baseUrl
    disableEcommerce
  }
`

const query = gql`
  query StoreConfig {
    storeConfig {
      ...StoreConfigFragment
    }
  }
  ${storeConfigFragment}
` as TypedDocumentNode<StoreConfigQuery>

export default async function getStoreConfig(client: ApolloClient<unknown>) {
  const { data } = await client.query({
    query,
    context: getCacheableContext(),
  })

  return data.storeConfig
}

export function useStoreConfig() {
  const { data, loading } = useQuery(query, {
    context: getCacheableContext(),
  })

  return {
    storeConfig: data?.storeConfig,
    isLoading: loading,
  }
}
