import {
  PreviousArrowIcon,
  NextArrowIcon,
  ShoppingCartIcon as BaseShoppingCartIcon,
  SearchIcon as BaseSearchIcon,
  ChevronLeftIcon as BaseChevronLeftIcon,
  ChevronRightIcon as BaseChevronRightIcon,
  CrossIcon as BaseCrossIcon,
} from '@emico/icons'

import ArrowLeftIcon from '../icons/ArrowLeftIcon'
import ArrowRightIcon from '../icons/ArrowRightIcon'
import ChevronLeftIcon from '../icons/ChevronLeftIcon'
import ChevronRightIcon from '../icons/ChevronRightIcon'
import CrossIcon from '../icons/CrossIcon'
import SearchIcon from '../icons/SearchIcon'
import ShoppingCartIcon from '../icons/ShoppingCartIcon'

BaseChevronLeftIcon.override(ChevronLeftIcon)
BaseChevronRightIcon.override(ChevronRightIcon)
BaseCrossIcon.override(CrossIcon)
PreviousArrowIcon.override(ArrowLeftIcon)
NextArrowIcon.override(ArrowRightIcon)
BaseShoppingCartIcon.override(ShoppingCartIcon)
BaseSearchIcon.override(SearchIcon)
