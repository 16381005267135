import React, { SVGProps } from 'react'

const SearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 20"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M13.119 11.928a7.36 7.36 0 001.593-4.572C14.712 3.3 11.412 0 7.356 0S0 3.3 0 7.356s3.3 7.356 7.356 7.356a7.39 7.39 0 004.58-1.601l6.921 6.808.089.081 1.086-1.103.08-.088-7.001-6.881h.008zm-.08-4.572a5.692 5.692 0 01-5.683 5.682 5.692 5.692 0 01-5.682-5.682 5.692 5.692 0 015.682-5.682 5.692 5.692 0 015.682 5.682z"
      fill="currentColor"
    />
  </svg>
)

export default SearchIcon
