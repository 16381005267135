import styled from '@emotion/styled'

import { TooltipBubbleArrow as BaseTooltipBubbleArrow } from '@emico/tooltip'

import theme from '../theme'

const TooltipBubbleArrow = styled(BaseTooltipBubbleArrow.original)`
  z-index: 0;
  border-color: ${theme.colors.border};

  &::before {
    background: ${theme.colors.white};
  }

  [data-popper-placement^='top'] & {
    &::before {
      border-bottom: ${theme.borders.default};
      border-right: ${theme.borders.default};
    }
  }

  [data-popper-placement^='bottom'] & {
  }

  [data-popper-placement^='left'] & {
  }

  [data-popper-placement^='right'] & {
  }
`

BaseTooltipBubbleArrow.override(
  TooltipBubbleArrow as typeof BaseTooltipBubbleArrow.original,
)
