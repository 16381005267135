import styled from '@emotion/styled'

import {
  StyledInput as BaseStyledInput,
  StyledButton as BaseStyledButton,
  StyledSearchIcon as BaseStyledSearchIcon,
  CancelButton as BaseCancelButton,
} from '@emico/search-bar'
import {
  StyledSearchBarForm as BaseStyledSearchBarForm,
  StyledBackdrop as BaseStyledBackdrop,
} from '@emico/search-bar-autocomplete'
import { minWidth, maxWidth } from '@emico/styles'

import theme from '../theme'

const StyledSearchBarForm = styled(BaseStyledSearchBarForm.original, {
  shouldForwardProp: (prop) => !['active'].includes(prop.toString()),
})<{ active?: boolean }>`
  z-index: ${({ active }) => active && theme.zIndices.search};
  box-shadow: none;

  @media ${minWidth('sm')} {
    box-shadow: none;
  }
`

export const StyledInput = styled(BaseStyledInput.original, {
  shouldForwardProp: (prop) => prop !== 'hasCurrentSearch',
})<{ hasCurrentSearch: boolean }>`
  background-color: ${theme.colors.grayLight};
  font-size: ${theme.fontSizes.sm};
  color: ${theme.colors.grayBrown};
  border: ${theme.borders.default};
  border-radius: ${theme.borderRadius.base};
  transition-property: padding;
  transition-duration: ${theme.transition.durations.normal};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};

  // maxWidth override
  @media ${maxWidth('sm')} {
    padding: ${({ hasCurrentSearch }) =>
      hasCurrentSearch
        ? `${theme.spacing.sm} ${theme.spacing.lg} ${theme.spacing.sm} ${theme.spacing.xl}`
        : `${theme.spacing.sm} ${theme.spacing.lg}`};
  }

  @media ${minWidth('md')} {
    padding-left: ${theme.spacing.lg};
  }

  @media ${minWidth('lg')} {
    padding: ${theme.spacing.xs} 0 ${theme.spacing.xs} ${theme.spacing.sm};
  }

  &:focus,
  &:active {
    box-shadow: none;
    outline: 1px solid currentColor;
    outline-offset: -4px;

    @media ${minWidth('lg')} {
      padding-left: ${theme.spacing.sm};
    }
  }

  &::placeholder {
    color: ${theme.colors.grayBrown};
  }
`

export const StyledSearchIcon = styled(BaseStyledSearchIcon.original)`
  color: ${theme.colors.grayBrown};
  font-size: 18px;
`

export const StyledCancelButton = styled(BaseCancelButton.original)`
  color: ${theme.colors.grayBrown};
  font-size: 14px;
`

const StyledButton = styled(BaseStyledButton.original)`
  @media ${minWidth('md')} {
    left: auto;
    right: 0;
  }
`

const StyledBackdrop = styled(BaseStyledBackdrop.original)`
  top: calc((${theme.sizes.headerHeight} + ${theme.sizes.uspBarHeight}));

  @media ${maxWidth('md')} {
    background-color: ${theme.colors.background};
  }
`

BaseStyledSearchBarForm.override(
  StyledSearchBarForm as typeof BaseStyledSearchBarForm.original,
)
BaseStyledInput.override(StyledInput as typeof BaseStyledInput.original)
BaseStyledButton.override(StyledButton as typeof BaseStyledButton.original)
BaseStyledSearchIcon.override(
  StyledSearchIcon as typeof BaseStyledSearchIcon.original,
)
BaseCancelButton.override(
  StyledCancelButton as typeof BaseCancelButton.original,
)
BaseStyledBackdrop.override(
  StyledBackdrop as typeof BaseStyledBackdrop.original,
)
