import { css } from '@emotion/react'
import styled from '@emotion/styled'

import {
  Spinner as BaseSpinner,
  PlusButton as BasePlusButton,
  MinButton as BaseMinButton,
} from '@emico/input'

import theme from '../theme'

const StyledSpinner = styled(BaseSpinner.original)`
  input {
    width: 2em;
    border: ${theme.borders.base} ${theme.colors.grayMiddle};
    aspect-ratio: 1 / 1;
    border-left-width: 0;
    border-right-width: 0;

    &:focus {
      box-shadow: none;
      outline: 0;
      border: ${theme.borders.base} ${theme.colors.grayBrown};
    }
  }
`

const buttonStyling = css`
  padding: ${theme.spacing.xs};
  width: 2em;
  background: none;
  border: ${theme.borders.base} ${theme.colors.grayMiddle};
  color: ${theme.colors.text};
  transition-property: ${theme.transition.properties.colors};
  transition-duration: ${theme.transition.durations.normal};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};

  &:focus,
  &:active {
    box-shadow: none;
  }

  &:disabled {
    background-color: ${theme.colors.grayMiddle};
  }

  &:not(:disabled):hover {
    background: none;
    border-color: ${theme.colors.grayBrown};
  }
`

const StyledPlusButton = styled(BasePlusButton.original)`
  ${buttonStyling}
`

const StyledMinButton = styled(BaseMinButton.original)`
  ${buttonStyling}
`

BaseSpinner.override(StyledSpinner as typeof BaseSpinner.original)
BasePlusButton.override(StyledPlusButton as typeof BasePlusButton.original)
BaseMinButton.override(StyledMinButton as typeof BaseMinButton.original)
