import Head from 'next/head'
import React from 'react'
import { helmetJsonLdProp } from 'react-schemaorg'
import { Thing, WithContext } from 'schema-dts'

/**
 * Source: https://github.com/google/react-schemaorg/blob/master/src/json-ld.tsx#L20
 * */
interface JsonLdOptions {
  /**
   * Adds indentation, white space, and line break characters to JSON-LD output. {@link JSON.stringify}
   * */
  space?: string | number
}

interface Props<T extends Thing> extends JsonLdOptions {
  item: WithContext<T>
}

/**
 * Next.js compatible component to place application/ld+json script in head
 * Script from next/script is not being used by choice, as per Google, it is recommended to add schema code in the head section
 * Clone of https://github.com/google/react-schemaorg#plain-react-usage
 */

const SchemaJsonLd = <T extends Thing>({ item, ...options }: Props<T>) => {
  const jsonLd = helmetJsonLdProp<T>(item, options)

  return (
    <Head>
      <script type={jsonLd.type}>{jsonLd.innerHTML}</script>
    </Head>
  )
}

export default SchemaJsonLd
