import styled from '@emotion/styled'

import ModalBackdrop from '@emico/modal-backdrop'

import theme from '../theme'

const StyledModalBackdrop = styled(ModalBackdrop.original)`
  background-color: rgba(0, 0, 0, 0.25);
  z-index: ${theme.zIndices.overlay};
`

ModalBackdrop.override(StyledModalBackdrop as typeof ModalBackdrop.original)
