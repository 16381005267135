import styled from '@emotion/styled'

import { H1, H2, H3, H4, H5, H6 } from '@emico/ui'

import theme from '../theme'

H1.override(styled.h1`
  font-family: ${theme.fonts.heading};
  font-size: ${theme.fontSizes['4xl']};
  font-weight: ${theme.fontWeights.bold};
  color: var(--color-h1, ${theme.colors.text});
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.02em;
`)

H2.override(styled.h2`
  font-family: ${theme.fonts.heading};
  font-size: ${theme.fontSizes.lg};
  font-weight: ${theme.fontWeights.bold};
  color: var(--color-h2, ${theme.colors.text});
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.02em;
`)

H3.override(styled.h3`
  font-family: ${theme.fonts.heading};
  font-size: ${theme.fontSizes.md};
  font-weight: ${theme.fontWeights.normal};
  color: var(--color-h3, ${theme.colors.text});
  margin: 0;
  letter-spacing: 0.02em;
`)

H4.override(styled.h4`
  font-family: ${theme.fonts.heading};
  font-size: ${theme.fontSizes.md};
  font-weight: ${theme.fontWeights.normal};
  color: var(--color-h4, ${theme.colors.text});
  margin: 0;
  letter-spacing: 0.02em;
`)

H5.override(styled.h5`
  font-family: ${theme.fonts.body};
  font-size: ${theme.fontSizes.md};
  font-weight: ${theme.fontWeights.normal};
  color: var(--color-h5, ${theme.colors.text});
  margin: 0;
`)

H6.override(styled.h6`
  font-family: ${theme.fonts.body};
  font-size: ${theme.fontSizes.sm};
  font-weight: ${theme.fontWeights.normal};
  color: var(--color-h6, ${theme.colors.text});
  margin: 0;
`)
