import { gql, TypedDocumentNode, ApolloClient, useQuery } from '@apollo/client'

import { Unmaybe } from '@emico/utils'

import {
  CraftIconLinksFragment,
  CraftImageFragment,
  CraftImageWithProductOverviewsFragment,
  CraftLinkFragment,
  CraftLinkListFragment,
  CraftLinkGroupListFragment,
  CraftMenuItemFragment,
  CraftUspFragment,
  CraftGenericBlockLinkFragment,
} from './craftFragments'
import { fetchTopCategoryList } from './fetchCategoryList'
import { getCraftSiteProps } from './getCraftSiteProps'
import Unwrap from './UnWrap'
import {
  DefaultLayoutQuery,
  DefaultLayoutQueryVariables,
} from './useCraftGlobalSets.generated'
import useLocale from './useLocale'

export interface DefaultLayoutStaticData {
  websiteData?: Unmaybe<DefaultLayoutQuery, 'globalSets'>
}

export type GlobalSetType = Unwrap<
  Unmaybe<Exclude<DefaultLayoutStaticData['websiteData'], null>>
>

export const defaultLayoutQuery = gql`
  query DefaultLayout($site: [String], $preferSites: [CraftQueryArgument]) {
    globalSets(site: $site, preferSites: $preferSites, unique: true) {
      id

      ... on CraftMenuGlobalSet {
        image {
          ...CraftImageFragment
          mobileUrl: url(width: 750, height: 350)
        }

        menuLinks {
          ... on CraftLinksBlockEntry {
            linkItem {
              ...CraftLinkFragment
            }
          }
        }

        iconLinks {
          ...CraftIconLinksFragment
        }

        menuItems {
          ... on CraftMenuItem2Entry {
            categoryId

            deviatingUrl {
              ...CraftLinkFragment
            }

            menuItem {
              ...CraftMenuItemFragment
            }
          }
        }
      }

      ... on CraftSearchSuggestionsGlobalSet {
        popularSearchTermsTitle

        popularSearchTerms {
          ...CraftGenericBlockLinkFragment
        }
      }

      ... on CraftFooterGlobalSet {
        title: title2
        copyright: text1

        linkGroups {
          ...CraftLinkGroupListFragment
        }

        footerLinks {
          ... on CraftLinksBlockEntry {
            linkItem {
              ...CraftLinkFragment
            }
          }
        }
      }

      ... on CraftCheckoutGlobalSet {
        cartCouponDisclaimer: text1
        paymentDisclaimer: richText2
        klarnaDisclaimer: richText3
        successTitle: title2
        successSubtitle: subtitle
        successText: richText1
      }

      ... on CraftCheckoutFooterGlobalSet {
        iconLinks {
          ...CraftIconLinksFragment
        }
      }

      ... on CraftContactGlobalGlobalSet {
        siteGlobalContact {
          ... on CraftSiteGlobalContactBlockEntry {
            id
            instagram {
              ...CraftLinkFragment
            }
            facebook {
              ...CraftLinkFragment
            }
            youtube {
              ...CraftLinkFragment
            }
            linkedin {
              ...CraftLinkFragment
            }
            organizationUrl
            addressStreet
            addressPostalCode
            addressLocality
            telephoneCustomerService
            emailCustomerService
            telephoneHeadquarters
          }
        }
      }

      ... on CraftUspsGlobalGlobalSet {
        usps {
          ...CraftUspFragment
        }
      }

      ... on CraftUspsProductPageGlobalSet {
        usps {
          ...CraftUspFragment
        }
      }

      ... on CraftInstagramImagesGlobalGlobalSet {
        imageWithProductOverviews {
          ...CraftImageWithProductOverviewsFragment
        }
      }

      ... on CraftDisclaimersGlobalGlobalSet {
        richTextDisclaimerTermsAndConditions: richTextSimple1
        richTextDisclaimerNewsletter: richTextSimple2
        richTextDisclaimerReview: richTextSimple3
      }

      ... on CraftEcommerceGlobalSet {
        disabledEcommerceLink: linkField {
          ...CraftLinkFragment
        }
      }
    }
  }

  ${CraftUspFragment}
  ${CraftImageFragment}
  ${CraftLinkListFragment}
  ${CraftLinkGroupListFragment}
  ${CraftIconLinksFragment}
  ${CraftLinkFragment}
  ${CraftMenuItemFragment}
  ${CraftGenericBlockLinkFragment}
  ${CraftImageWithProductOverviewsFragment}
` as TypedDocumentNode<DefaultLayoutQuery, DefaultLayoutQueryVariables>

export function useCraftGlobalSets() {
  const locale = useLocale()

  const { data, error, loading, ...others } = useQuery(defaultLayoutQuery, {
    variables: {
      ...getCraftSiteProps(locale),
    },
  })

  return {
    ...others,
    loading,
    data: data?.globalSets ?? undefined,
    error,
  }
}

export async function getStaticProps(
  client: ApolloClient<unknown>,
  locale?: string,
): Promise<DefaultLayoutStaticData> {
  const { data } = await client.query({
    query: defaultLayoutQuery,
    variables: {
      ...getCraftSiteProps(locale),
    },
  })

  await fetchTopCategoryList(client, 2)

  if (!data.globalSets) {
    throw new Error('Fetching craft globalSets data failed')
  }

  return {
    websiteData: data.globalSets,
  }
}

export default getStaticProps
