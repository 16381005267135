import defaultsToVars, { defaultsToCss } from '../lib/cssVarsUtils'

const spacingPrefix = 'spacing'

export const spacingDefaults = {
  xxs: '2px',
  xs: '5px',
  sm: '10px',
  md: '15px',
  lg: '20px',
  xl: '30px',
  '2xl': '40px',
  '3xl': '60px',
  '4xl': '100px',
}

const spacing = defaultsToVars(spacingDefaults, spacingPrefix)

const defaults = {
  containerPadding: `${spacing.md}`,
  pagePadding: spacing['2xl'],
  pagePaddingLg: spacing['3xl'],
}

export const spacingLg = defaultsToCss<typeof defaults>(
  {
    containerPadding: `${spacing.lg}`,
    pagePadding: spacing['2xl'],
    pagePaddingLg: spacing['3xl'],
  },
  spacingPrefix,
)

export const spacingCss = [
  ...defaultsToCss(spacingDefaults, spacingPrefix),
  ...defaultsToCss(defaults, spacingPrefix),
]

export default {
  spacing,
  ...defaultsToVars(defaults, spacingPrefix),
}
