import { CheckboxCustomVisibleButton as BaseCheckboxCustomVisibleButton } from '@emico-react/input-checkbox'
import styled from '@emotion/styled'

import theme from '../theme'

const StyledCheckboxCustomVisibleButton = styled(
  BaseCheckboxCustomVisibleButton.original,
)`
  --emico-checkbox-custom-visible-button-active-color: ${theme.colors.grayDark};
  --emico-checkbox-custom-visible-button-focus-color: ${theme.colors.grayBrown};
  --emico-checkbox-custom-visible-button-active-color-disabled: ${theme.colors
    .grayMiddle};
  --emico-checkbox-custom-visible-button-border: 1px solid
    ${theme.colors.grayBrown};
  --emico-checkbox-custom-visible-button-border-disabled: 2px solid
    ${theme.colors.grayMiddle};
  --emico-checkbox-custom-visible-button-background: ${theme.colors.grayDark};
  --emico-checkbox-custom-visible-button-checkmark-color: ${theme.colors.white};
  --emico-checkbox-custom-visible-button-border-radius: 2px;
  margin-right: ${theme.spacing.sm};
`

BaseCheckboxCustomVisibleButton.override(
  StyledCheckboxCustomVisibleButton as typeof BaseCheckboxCustomVisibleButton.original,
)
