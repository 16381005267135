import defaultsToVars, { defaultsToCss } from '../lib/cssVarsUtils'
import colors from './colors'

const borderPrefix = 'border'
const borderRadiusPrefix = 'border-radius'

const borders = {
  none: 0,
  base: '1px solid',
  md: '2px solid',
  lg: '4px solid',
}

const borderRadius = {
  base: '2px',
  large: '4px',
}

const defaults = {
  default: `${borders.base} ${colors.border}`,
}

export const bordersCss = [
  ...defaultsToCss(borders, borderPrefix),
  ...defaultsToCss(defaults, borderPrefix),
  ...defaultsToCss(borderRadius, borderRadiusPrefix),
]

export default {
  borders: {
    ...defaultsToVars(borders, borderPrefix),
    ...defaultsToVars(defaults, borderPrefix),
  },
  borderRadius: defaultsToVars(borderRadius, borderRadiusPrefix),
}
