import styled from '@emotion/styled'
import React, { forwardRef, Ref, SelectHTMLAttributes } from 'react'
import { Control, FieldValues, Path, useController } from 'react-hook-form'

import { Select as BaseSelect } from '@emico/input'

import { getDefaultErrorMessage } from '../lib/formErrorMessages'
import theme from '../theme'
import FormField from './FormField'

const StyledFormField = styled(FormField)`
  position: relative;
`

export const StyledBaseSelect = styled(BaseSelect.original, {
  shouldForwardProp: (prop) => !['hasError'].includes(prop.toString()),
})<{ hasError?: boolean }>`
  padding: ${theme.spacing.md} ${theme.spacing.xl} ${theme.spacing.md}
    ${theme.spacing.sm};
  width: 100%;
  background: url(data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhbCIgZGF0YS1pY29uPSJhbmdsZS1kb3duIiByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDI1NiA1MTIiIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS1hbmdsZS1kb3duIGZhLXctOCBmYS0zeCI+PHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBkPSJNMTE5LjUgMzI2LjlMMy41IDIwOS4xYy00LjctNC43LTQuNy0xMi4zIDAtMTdsNy4xLTcuMWM0LjctNC43IDEyLjMtNC43IDE3IDBMMTI4IDI4Ny4zbDEwMC40LTEwMi4yYzQuNy00LjcgMTIuMy00LjcgMTcgMGw3LjEgNy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wxMzYuNSAzMjdjLTQuNyA0LjYtMTIuMyA0LjYtMTctLjF6IiBjbGFzcz0iIj48L3BhdGg+PC9zdmc+Cg==)
    no-repeat;
  background-position: right 10px center;
  background-size: 14px;
  border: ${theme.borders.base} ${theme.colors.borderDark};
  border-color: ${({ hasError }) => hasError && theme.colors.error};
  border-radius: ${theme.borderRadius.base};
  color: ${theme.colors.text};
  appearance: none;

  &:disabled {
    color: ${theme.colors.grayBrown};
  }

  &:focus {
    box-shadow: none;
    outline: 1px solid currentColor;
    outline-offset: -4px;
  }
`

const InputError = styled.p`
  margin: ${theme.spacing.xs} 0 0;
  color: ${theme.colors.error};
  font-size: ${theme.fontSizes.sm};
`

interface Props<T extends FieldValues>
  extends SelectHTMLAttributes<HTMLSelectElement> {
  /**
   * pass control from parent in order to get input fieldState (`const { control } = useForm()`)
   */
  control: Control<T>
  /**
   * optional custom error message
   */
  customErrorMessage?: string
  /**
   * Type fix, passed from useForm's register
   */
  name: Path<T>
}

const Select = <T extends FieldValues>(
  { control, customErrorMessage, className, ...other }: Props<T>,
  ref: Ref<HTMLSelectElement>,
) => {
  const { fieldState } = useController({
    name: other.name ?? '',
    control: control,
  })

  /**
   * Error message:
   * - If error from fieldState occurs, show error
   * - If no error from fieldState occurs, but customErrorMessage exists, show custom error
   * (e.g. when hook result error state returns after input registration validation)
   * - If no message is added to fieldState error, and no customErrorMessage exists, show default error based on error type
   */
  const errorMessage =
    fieldState.error?.message ??
    customErrorMessage ??
    (fieldState.error
      ? getDefaultErrorMessage(fieldState.error.type)
      : undefined)

  const hasError = Boolean(fieldState.error)
  const hasErrorMessage = Boolean(errorMessage)

  return (
    <StyledFormField fieldIdentifier={other.name ?? ''} className={className}>
      <StyledBaseSelect
        id={other.name}
        ref={ref}
        hasError={hasError}
        {...other}
      />

      {hasErrorMessage && <InputError>{errorMessage}</InputError>}
    </StyledFormField>
  )
}

Select.displayName = 'Select'

// Ignore type instantiation
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default forwardRef(Select) as typeof Select
