import Head from 'next/head'
import { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

/**
 * @deprecated Do not use in NextJS, this will cause memory leaks. Use next/head instead
 */
function HelmetExport({ children, ...props }: Props) {
  if (children) {
    if (process.env.NODE_ENV === 'development') {
      console.trace('Helmet used instead of next/head')
    }
    return <Head>{children}</Head>
  }

  console.warn('Helmet is not supported', props)

  return null
}

export default HelmetExport
export { HelmetExport as Helmet }
