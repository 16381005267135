import styled from '@emotion/styled'
import Image from 'next/image'
import React from 'react'

import LogoImage from '../images/berg_logo.png'
import theme from '../theme'

const StyledNextImage = styled(Image)`
  width: ${theme.sizes.logoWidth};
  height: auto;
`

const Logo = ({ ...props }) => (
  <StyledNextImage
    width={257}
    height={131}
    src={LogoImage}
    alt="BergToysLogo"
    {...props}
  />
)

export default Logo
