import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

import theme from '../theme'

const Label = styled.label`
  font-weight: ${theme.fontWeights.bold};
  margin-bottom: ${theme.spacing.sm};
`

const SubLabel = styled.span`
  font-weight: ${theme.fontWeights.normal};
`

/**
 * FormField component to wrap form elements
 *
 * children : renders children within FormField
 * fieldIdentifier : links label to input for accessibility, if input has same id attribute
 * label : optional input label to describe form element
 */
interface Props {
  children: ReactNode
  fieldIdentifier: string
  className?: string
  label?: string | ReactNode
  subLabel?: string | ReactNode
}

const FormField = ({
  children,
  fieldIdentifier,
  label,
  subLabel,
  ...other
}: Props) => (
  <div {...other}>
    {label && (
      <Label htmlFor={fieldIdentifier}>
        {label} <SubLabel>{subLabel}</SubLabel>
      </Label>
    )}

    {children}
  </div>
)

export default FormField
