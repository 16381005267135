import styled from '@emotion/styled'

import {
  KeywordSuggestion as BaseKeywordSuggestion,
  Label as BaseLabel,
  StyledKeywordSuggestions as BaseStyledKeywordSuggestions,
  Highlight as BaseHighlight,
} from '@emico/search-bar-autocomplete'
import { minWidth, maxWidth } from '@emico/styles'

import theme from '../theme'

export const StyledKeywordSuggestions = styled(
  BaseStyledKeywordSuggestions.original,
)`
  margin-top: 0;
  box-shadow: none;
  z-index: ${theme.zIndices.search};

  @media ${minWidth('sm')} {
    box-shadow: none;
  }

  @media ${minWidth('lg')} {
    left: 0;
    top: ${theme.sizes.headerHeight};
  }
`

const KeywordSuggestion = styled(BaseKeywordSuggestion.original)`
  padding: ${theme.spacing.sm} ${theme.spacing.xl} ${theme.spacing.sm} 0;
  transition-property: ${theme.transition.properties.colors};
  transition-duration: ${theme.transition.durations.normal};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};

  // maxWidth override
  @media ${maxWidth('md')} {
    padding: 0 0 ${theme.spacing.md} 0;
  }

  @media ${minWidth('lg')} {
    padding-left: ${theme.spacing.xl};

    &:hover {
      color: ${theme.colors.primary};
    }
  }

  &:first-of-type {
    padding-top: ${theme.spacing.xl};
  }

  &:last-of-type {
    padding-bottom: ${theme.spacing.xl};
  }

  &:hover {
    font-weight: ${theme.fontWeights.normal};
  }
`

const Label = styled(BaseLabel.original)`
  padding: ${theme.spacing.xl} ${theme.spacing.xl} 0;
  font-weight: ${theme.fontWeights.medium};

  // maxWidth override
  @media ${maxWidth('md')} {
    padding: ${theme.spacing.lg} 0 0;
  }
`

const Highlight = styled(BaseHighlight.original)`
  font-weight: ${theme.fontWeights.medium};
`

BaseStyledKeywordSuggestions.override(
  StyledKeywordSuggestions as typeof BaseStyledKeywordSuggestions.original,
)
BaseKeywordSuggestion.override(
  KeywordSuggestion as typeof BaseKeywordSuggestion.original,
)
BaseLabel.override(Label as typeof BaseLabel.original)
BaseHighlight.override(Highlight as typeof BaseHighlight.original)
