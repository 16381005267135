/* eslint-disable react/jsx-pascal-case */
import {
  ArrowButton as BaseArrowButton,
  PrevButton as BasePrevButton,
  NextButton as BaseNextButton,
} from '@emico-react/component-slider'
import styled from '@emotion/styled'
import { ComponentProps } from 'react'

import ChevronLeftIcon from '../icons/ChevronLeftIcon'
import ChevronRightIcon from '../icons/ChevronRightIcon'
import theme from '../theme'

const SLIDER_BUTTON_SIZE = '40px'

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${SLIDER_BUTTON_SIZE};
  width: ${SLIDER_BUTTON_SIZE};
  background-color: ${theme.colors.grayLight};
  border-radius: 100%;
  color: ${theme.colors.grayDark};
  font-size: 14px;
  transition-property: ${theme.transition.properties.common};
  transition-duration: ${theme.transition.durations.normal};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};
`

export const ArrowButton = styled(BaseArrowButton.original)`
  position: absolute;
  top: calc(${theme.spacing.lg} * -1);
  transform: translateY(-100%);

  &:hover {
    ${IconWrapper} {
      background-color: ${theme.colors.grayLight};
    }
  }

  &:disabled {
    ${IconWrapper} {
      background-color: ${theme.colors.grayLight};
      opacity: 0.5;
    }
  }
`

export const PrevButton = styled(BasePrevButton.original)`
  right: calc(${SLIDER_BUTTON_SIZE} + ${theme.spacing.md});
  left: auto;
`

// Used because we need this class in QuickFilters since the swc emotion loader cant get classNames
// from components in node_modules I guess.
export const StyledNextButton = styled(BaseNextButton.original)``

const Prev = (props: ComponentProps<typeof BasePrevButton>) => (
  <PrevButton {...props}>
    <IconWrapper>
      <ChevronLeftIcon />
    </IconWrapper>
  </PrevButton>
)

const NextButton = (props: ComponentProps<typeof BaseNextButton>) => (
  <StyledNextButton {...props}>
    <IconWrapper>
      <ChevronRightIcon />
    </IconWrapper>
  </StyledNextButton>
)

BaseArrowButton.override(ArrowButton as typeof BaseArrowButton.original)
BasePrevButton.override(Prev as typeof BasePrevButton.original)
BaseNextButton.override(NextButton as typeof BaseNextButton.original)
