import React, { SVGProps } from 'react'

const ChevronRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 8 14"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M1 13.998a.997.997 0 01-.769-1.636l4.47-5.348-4.31-5.358A.998.998 0 01.54.249a.998.998 0 011.456.15l4.82 5.986a.998.998 0 010 1.267l-4.99 5.987a.998.998 0 01-.827.36z"
      fill="currentColor"
    />
  </svg>
)

export default ChevronRightIcon
