import defaultsToVars, { defaultsToCss } from '../lib/cssVarsUtils'

const fontPrefix = 'font'
const fontSizePrefix = 'font-size'
const fontWeightPrefix = 'font-weight'
const lineHeightPrefix = 'line-height'

const fonts = {
  heading: "'PF Centro Sans Pro', sans-serif",
  body: "'PF Centro Sans Pro', sans-serif",
  mono: 'monospace',
}

const fontWeights = {
  light: 300,
  normal: 400,
  medium: 500,
  bold: 700,
  black: 900,
}

const fontSizes = {
  xs: '13px',
  sm: '14px',
  md: '16px',
  lg: '18px',
  xl: '20px',
  '2xl': '25px',
  '3xl': '30px',
  '4xl': '35px',
}

const lineHeights = {
  xs: 1.23,
  sm: 1.571,
  md: 1.172,
  lg: 1.333,
  xl: 1.142,
}

export const typographyCss = [
  ...defaultsToCss(fonts, fontPrefix),
  ...defaultsToCss(fontWeights, fontWeightPrefix),
  ...defaultsToCss(fontSizes, fontSizePrefix),
  ...defaultsToCss(lineHeights, lineHeightPrefix),
]

export default {
  fonts: defaultsToVars(fonts, fontPrefix),
  fontWeights: defaultsToVars(fontWeights, fontWeightPrefix),
  fontSizes: defaultsToVars(fontSizes, fontSizePrefix),
  lineHeights: defaultsToVars(lineHeights, lineHeightPrefix),
}
