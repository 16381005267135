export const getCraftSites = (locale?: string) => {
  const activeStoreView = locale ?? 'nl'

  switch (activeStoreView) {
    // EN
    case 'int_en':
      return ['default']
    // NL
    case 'nl':
      return ['b2c_nl', 'int_nl']
    case 'be_nl':
      return ['b2c_be_nl', 'int_nl']
    // DE
    case 'de':
      return ['b2c_de', 'int_de']
    case 'at':
      return ['b2c_at', 'int_de']
    case 'ch_de':
      return ['b2c_ch_de', 'int_de']
    // FR
    case 'fr':
      return ['b2c_fr', 'int_fr']
    case 'be_fr':
      return ['b2c_be_fr', 'int_fr']
    case 'ch_fr':
      return ['b2c_ch_fr', 'int_fr']
    // ES
    case 'int_es':
      return ['b2c_es']
    case 'es':
      return ['b2c_es_es', 'b2c_es']
    // OTHER
    default:
      return [`b2c_${activeStoreView}`, 'default']
  }
}

interface CraftSiteProps {
  preferSites: string[]
  site: string[]
}

export function getCraftSiteProps(locale?: string): CraftSiteProps {
  const sites = getCraftSites(locale)

  return {
    preferSites: sites?.slice(0, 1) ?? [],
    site: sites ?? [],
  }
}
