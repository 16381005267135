import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

export type MakeUrl = (query: string) => string

const defaultMakeUrl: MakeUrl = (query: string) => {
  const params = new URLSearchParams({ query })
  return `/search?${params.toString()}`
}

const useSearch = (makeUrl: MakeUrl = defaultMakeUrl) => {
  const history = useHistory()

  return useCallback(
    (query: string) => {
      history.push(makeUrl(query))
    },
    [history, makeUrl],
  )
}

export default useSearch
