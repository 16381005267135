import styled from '@emotion/styled'

import { AnimatedElement as BaseAnimatedElement } from '@emico/modal-segue'

import theme from '../theme'

const StyledAnimatedElement = styled(BaseAnimatedElement.original)`
  z-index: ${theme.zIndices.modal};
`

BaseAnimatedElement.override(
  StyledAnimatedElement as typeof BaseAnimatedElement.original,
)
