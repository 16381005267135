import styled from '@emotion/styled'

import {
  RatingActiveStar as BaseRatingActiveStar,
  RatingBackgroundStar as BaseRatingBackgroundStar,
} from '@emico/ui'

import theme from '../theme'

const RatingActiveStar = styled(BaseRatingActiveStar.original)`
  color: ${theme.colors.yellow};
`

const RatingBackgroundStar = styled(BaseRatingBackgroundStar.original)`
  color: #2254e6;
`

BaseRatingActiveStar.override(
  RatingActiveStar as typeof BaseRatingActiveStar.original,
)

BaseRatingBackgroundStar.override(
  RatingBackgroundStar as typeof BaseRatingBackgroundStar.original,
)
