import styled from '@emotion/styled'
import React, { ComponentProps } from 'react'

import { TooltipBubble as BaseTooltipBubble } from '@emico/tooltip'

import theme from '../theme'

const StyledTooltipBubble = styled(BaseTooltipBubble.original)`
  border-color: ${theme.colors.border};
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.07);
`

const TooltipBubble = (props: ComponentProps<typeof BaseTooltipBubble>) => (
  <StyledTooltipBubble {...props} />
)

BaseTooltipBubble.override(TooltipBubble as typeof BaseTooltipBubble)
