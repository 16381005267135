import defaultsToVars, { defaultsToCss } from '../lib/cssVarsUtils'

// https://csstriggers.com/
const propertiesDefaults = {
  common:
    'background-color, border-color, color, fill, stroke, opacity, box-shadow, transform',
  colors: 'background-color, border-color, color, fill, stroke',
  dimensions: 'width, height',
  position: 'left, right, top, bottom',
  background: 'background-color, background-image, background-position',
}

export const properties = defaultsToVars(
  propertiesDefaults,
  'transition-properties',
)

const timingFunctionsDefaults = {
  easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
  easeOut: 'cubic-bezier(0, 0, 0.2, 1)',
  easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
  cubicBezierSmooth: 'cubic-bezier(0.19, 1, 0.22, 1)',
}

export const timingFunctions = defaultsToVars(
  timingFunctionsDefaults,
  'transition-timing-function',
)

const durationsDefaults = {
  fast: '200ms',
  normal: '300ms',
  slow: '400ms',
  extraSlow: '800ms',
}

export const durations = defaultsToVars(
  durationsDefaults,
  'transition-duration',
)

const transitions = {
  properties,
  timingFunctions,
  durations,
}

export const transitionCss = [
  ...defaultsToCss(propertiesDefaults, 'transition-properties'),
  ...defaultsToCss(timingFunctionsDefaults, 'transition-timing-function'),
  ...defaultsToCss(durationsDefaults, 'transition-duration'),
]

export const reducedMotionProps = [
  ...defaultsToCss<typeof propertiesDefaults>(
    {
      common: 'transform, opacity',
      background: 'none',
      position: propertiesDefaults.position,
      dimensions: propertiesDefaults.dimensions,
      colors: propertiesDefaults.colors,
    },
    'transition-properties',
  ),
  ...defaultsToCss<typeof timingFunctionsDefaults>(
    {
      easeIn: 'easeInOut',
      easeInOut: 'easeInOut',
      easeOut: 'easeInOut',
      cubicBezierSmooth: 'cubicBezierSmooth',
    },
    'transition-timing-function',
  ),
  ...defaultsToCss<typeof durationsDefaults>(
    {
      fast: '200ms',
      normal: '300ms',
      slow: '400ms',
      extraSlow: '800ms',
    },
    'transition-duration',
  ),
]

export default transitions
