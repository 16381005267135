import styled from '@emotion/styled'

import { TableDataCell as BaseTableDataCell } from '@emico/ui'

import theme from '../theme'

const StyledTableDataCell = styled(BaseTableDataCell.original)`
  padding: ${theme.spacing.sm};
`

BaseTableDataCell.override(
  StyledTableDataCell as typeof BaseTableDataCell.original,
)
