import { ApolloClient, gql, TypedDocumentNode, useQuery } from '@apollo/client'
import { getCacheableContext } from '@emico-utils/graphql-data-utils'
import { useMemo } from 'react'

import categoryFragment from '@emico/category-fragment'
import {
  CategoryFragmentWithChildren,
  // categoryListQuery,
} from '@emico/category-graphql'

import {
  TopCategoryListQuery,
  TopCategoryListQueryVariables,
} from './fetchCategoryList.generated'

export const topCategoryListQuery = gql`
  query TopCategoryList($filters: CategoryFilterInput) {
    categories(filters: $filters) {
      items {
        ...CategoryFragment
        children {
          ...CategoryFragment
        }
      }
    }
  }
  ${categoryFragment}
` as TypedDocumentNode<TopCategoryListQuery, TopCategoryListQueryVariables>

export const useCachedRootCategory = (id: number | string) => {
  const { data, ...others } = useQuery(topCategoryListQuery, {
    variables: {
      filters: {
        ids: {
          eq: String(id),
        },
      },
    },
    fetchPolicy: 'cache-only',
    context: getCacheableContext(),
  })

  return useMemo(
    () => ({
      data: data?.categories?.items?.[0] as CategoryFragmentWithChildren,
      ...others,
    }),
    [data?.categories?.items, others],
  )
}

export async function fetchTopCategoryList(
  client: ApolloClient<unknown>,
  id: number | string,
) {
  return await client.query({
    query: topCategoryListQuery,
    variables: {
      filters: {
        ids: {
          eq: String(id),
        },
      },
    },
    context: getCacheableContext(),
    errorPolicy: 'all',
  })
}

// export default async function fetchCategoryList(
//   client: ApolloClient<unknown>,
//   id: number | string,
// ) {
//   return await client.query({
//     query: categoryListQuery,
//     variables: {
//       filters: {
//         ids: {
//           eq: String(id),
//         },
//       },
//     },
//     errorPolicy: 'all',
//     context: getCacheableContext(),
//   })
// }
