import { RadioCustom as BaseRadioCustom } from '@emico-react/input-radio'
import styled from '@emotion/styled'
import React, { ComponentProps, Ref, forwardRef, useState } from 'react'

import { minWidth } from '@emico/styles'

import CheckmarkIcon from '../icons/CheckmarkIcon'
import theme from '../theme'

const StyledRadioCustom = styled(BaseRadioCustom.original, {
  shouldForwardProp: (prop) =>
    !['hasCustomSelectState', 'isSelected'].includes(prop.toString()),
})<{ hasCustomSelectState?: boolean; isSelected?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  border-radius: ${theme.borderRadius.base};
  box-shadow: ${({ hasCustomSelectState, isSelected }) =>
    hasCustomSelectState &&
    (isSelected
      ? `0 0 0 2px ${theme.colors.borderDark}`
      : `0 0 0 1px ${theme.colors.border}`)};
  transition-property: ${theme.transition.properties.common};
  transition-duration: ${theme.transition.durations.extraSlow};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};
`

const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  font-size: 24px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  background-color: ${theme.colors.grayDark};
  color: ${theme.colors.white};
  border-radius: 50%;
  padding: 7px;

  @media ${minWidth('lg')} {
    font-size: 28px;
  }
`

interface Props extends ComponentProps<typeof BaseRadioCustom> {
  hasCustomSelectState?: boolean
  handleCustomRadioState?(isSelected: boolean): void
}

const RadioCustom = (
  {
    hasCustomSelectState = true,
    handleCustomRadioState,
    children,
    ...other
  }: Props,
  ref: Ref<HTMLInputElement>,
) => {
  const [isSelected, setIsSelected] = useState<boolean>(false)

  const handleStateChange = (isSelected: boolean) => {
    setIsSelected(isSelected)

    if (handleCustomRadioState) {
      handleCustomRadioState(isSelected)
    }
  }

  return (
    <StyledRadioCustom
      ref={ref}
      handleStateChange={handleStateChange}
      isSelected={isSelected}
      hasCustomSelectState={hasCustomSelectState}
      {...other}
    >
      {children}

      {isSelected && hasCustomSelectState && <StyledCheckmarkIcon />}
    </StyledRadioCustom>
  )
}

RadioCustom.displayName = 'RadioCustom'

export default forwardRef(RadioCustom) as typeof RadioCustom
