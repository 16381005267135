import { css } from '@emotion/react'
import { palette } from './colors'
import { durations, properties, timingFunctions } from './transitions'

/**
 *
 * @param lines : number of visible lines
 * @returns css instance
 */

const lineClamp = (lines: number) => css`
  display: -webkit-box;
  -webkit-line-clamp: ${lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`

/**
 *
 * @returns css instance
 */

const ellipsis = () => css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

/**
 * Assign transparentOverlay to an element that should have a transparent overlay
 * Any elements that should be visible on top of the overlay, should be assigned a z-index
 *
 * @param colorType : overlay color type: dark or light
 * @returns css instance
 */

const transparentOverlay = (colorType: 'dark' | 'light' = 'dark') => css`
  position: relative;
  z-index: 0;

  &::after {
    content: '';
    display: block;
    background: ${colorType === 'light' ? palette.white : palette.black};
    opacity: 0.4;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 0;
  }
`

/**
 * Assign transparentGradientOverlay to an element that should have a transparent overlay with a gradient
 * Any elements that should be visible on top of the overlay, should be assigned a z-index
 *
 * @param colorType : overlay color type: dark or light
 * @returns css instance
 */

const transparentGradientOverlay = (
  colorType: 'dark' | 'light' = 'dark',
) => css`
  position: relative;
  z-index: 0;

  &::after {
    content: '';
    display: block;
    background: ${colorType === 'light'
      ? `linear-gradient(
      0deg,
      rgb(255, 255, 255) 0%,
      rgba(255, 255, 255, 0) 50%
    )`
      : `linear-gradient(
      0deg,
      rgb(5, 28, 44) 0%,
      rgba(5, 28, 44, 0) 50%
    )`};
    opacity: 0.5;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 0;
  }
`

enum ColorThemeEnum {
  NONE = 'none',
  LIGHT = 'light',
  DARK = 'dark',
}

const getBackgroundColor = (colorType: 'none' | 'dark' | 'light') => {
  switch (colorType) {
    case ColorThemeEnum.LIGHT:
      return `linear-gradient(
        0deg,
        rgb(244, 244, 244) 0%,
        rgba(244, 244, 244, 0) 100%
      )`
    case ColorThemeEnum.DARK:
      return `linear-gradient(
        0deg,
        rgb(5, 28, 44) 0%,
        rgba(5, 28, 44, 0) 100%
      )`
    default:
      return `linear-gradient(
        0deg,
        rgb(255, 255, 255) 0%,
        rgba(255, 255, 255, 0) 100%
      )`
  }
}

/**
 * Assign textBlur to a text that should be partially blurred,
 * for example in a read more/less context
 *
 * @param show : should the text blur be visible?
 * @param colorType : color type: dark or light
 * @returns css instance
 */

const textBlur = (
  show: boolean,
  colorType: 'none' | 'dark' | 'light' = 'none',
) => css`
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: ${getBackgroundColor(colorType)};
    opacity: ${show ? 0 : 1};
    transition-property: ${properties.common};
    transition-duration: ${durations.extraSlow};
    transition-timing-function: ${timingFunctions.cubicBezierSmooth};
    pointer-events: none;
  }
`

export default {
  textBlur,
  transparentOverlay,
  transparentGradientOverlay,
  lineClamp,
  ellipsis,
}
