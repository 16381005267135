import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { t } from '@lingui/macro'
import { useRouter } from 'next/router'
import { useId } from 'react'
import React, {
  ComponentProps,
  MutableRefObject,
  KeyboardEvent,
  useEffect,
  useCallback,
} from 'react'
import { useForm } from 'react-hook-form'

import { SearchIcon } from '@emico/icons'
import { overrideOptional } from '@emico/overrides'
import { theme, maxWidth, minWidth } from '@emico/styles'
import { ButtonUnstyled } from '@emico/ui'

import useSearch, { MakeUrl } from './useSearch'

export const Container = overrideOptional(styled.label`
  display: block;
  position: relative;
  margin-bottom: 0;
`)

const absoluteButton = css`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 0;
  padding: 0;
`

export const StyledSearchIcon = overrideOptional(styled(SearchIcon)`
  width: 15px;
  height: 15px;

  @media ${minWidth('md')} {
    width: 18px;
    height: 18px;
  }
`)

export const StyledButton = overrideOptional(styled(ButtonUnstyled)`
  ${absoluteButton}
  transform: translate(50%, -50%);
  right: 15px;

  @media ${minWidth('md')} {
    left: 29px;
    right: auto;
    transform: translate(-50%, -50%);
  }
`)

export const SearchButton = overrideOptional(StyledButton)

export const CancelButton = overrideOptional(StyledButton)

export const BackButton = overrideOptional(styled(ButtonUnstyled)`
  ${absoluteButton}
  left: 15px;
`)

export const StyledInput = overrideOptional(styled('input', {
  shouldForwardProp: (prop) => prop !== 'hasCurrentSearch',
})<{ hasCurrentSearch: boolean }>`
  display: block;
  padding: 14px 14px 14px 58px;
  color: #212226;
  font-size: ${theme.fontSize};
  line-height: 1.6;
  width: 100%;
  border: 0;

  @media ${maxWidth('sm')} {
    padding: ${({ hasCurrentSearch }) =>
      hasCurrentSearch ? '0 30px' : '0 30px 0 5px'};

    :focus {
      background: ${theme.onPrimaryColor};
      border: 0;
      outline: 0;
    }
  }
`)

interface SearchOrCancelButtonProps {
  currentSearch: string
  onCancel: () => void
  onClose?: () => void
}

export const SearchOrCancelButton = overrideOptional(
  ({ currentSearch, onCancel, onClose }: SearchOrCancelButtonProps) => null,
)

interface FormValues {
  search: string
}

interface Props
  extends Omit<ComponentProps<typeof Container>, 'onChange' | 'onKeyDown'> {
  makeUrl?: MakeUrl
  onChange?: (searchQuery: string) => void
  onFocus?: () => void
  onBlur?: () => void
  onClose?: () => void
  onKeyDown?: (event: KeyboardEvent) => void
  autoComplete?: boolean
  submit?: MutableRefObject<((searchQuery?: string) => void) | null>
  onSearch?: (searchQuery: string) => void
  placeholder?: string
}

export const useActiveSearchQuery = () => {
  const { query } = useRouter()

  return String(query.query ?? '')
}

const SearchBar = ({
  makeUrl,
  onChange,
  onFocus,
  onBlur,
  onClose,
  onKeyDown,
  autoComplete,
  submit,
  onSearch,
  placeholder,
  ...others
}: Props) => {
  const id = useId()
  const search = useSearch(makeUrl)
  const { query } = useRouter()
  const { register, handleSubmit, watch, reset, setValue } =
    useForm<FormValues>({
      defaultValues: {
        search: String(query.query ?? ''),
      },
      mode: 'all',
    })

  const onSubmit = useCallback(
    (values: FormValues) => {
      const searchQuery = values.search ?? ''

      if (onSearch) {
        onSearch(searchQuery)

        return
      }

      search(searchQuery)
    },
    [onSearch, search],
  )

  const currentSearch = watch('search')

  useEffect(() => {
    if (!submit) {
      return
    }

    submit.current = (searchQuery?: string) => {
      if (searchQuery !== undefined) {
        setValue('search', searchQuery)
      }

      onSubmit({
        search: searchQuery ?? '',
      })
    }
  }, [onSubmit, setValue, submit])

  useEffect(() => {
    onChange?.(currentSearch)
  }, [currentSearch, onChange])

  return (
    <Container {...others}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SearchOrCancelButton
          currentSearch={currentSearch}
          onClose={onClose}
          onCancel={reset}
        />

        <StyledInput
          {...register('search')}
          id={id}
          placeholder={
            placeholder ??
            t({
              id: 'searchBar.placeholder',
              message: 'What are you looking for?',
            })
          }
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          hasCurrentSearch={Boolean(currentSearch)}
          autoComplete={autoComplete === false ? 'off' : undefined}
        />
      </form>
    </Container>
  )
}

export default overrideOptional(SearchBar)
