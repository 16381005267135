declare global {
  interface Window {
    __LINGUI_CATALOG__: string
  }
}

/**
 *
 * @param locale Locale is not used in this function. There is only one catalog loaded
 * @returns
 */
export default function getCatalog(locale: string) {
  const data: typeof window['__LINGUI_CATALOG__'] = JSON.parse(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.getElementById('__LINGUI_CATALOG__')!.textContent!,
  )

  window.__LINGUI_CATALOG__ = data

  return window.__LINGUI_CATALOG__
}
