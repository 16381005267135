import { minWidth } from '@emico/styles'
import { Global, css } from '@emotion/react'
import theme from './index'

import { colorsCss } from './colors'
import { spacingLg, spacingCss } from './spacing'
import { sizesCss, sizesLgCss, sizesXlCss } from './sizes'
import { transitionCss } from './transitions'
import { typographyCss } from './typography'
import { shadowCss } from './shadow'
import { bordersCss } from './borders'

const GlobalStyles = () => {
  const allVars = [
    ...bordersCss,
    ...colorsCss,
    ...spacingCss,
    ...sizesCss,
    ...typographyCss,
    ...transitionCss,
    ...shadowCss,
  ].join('')

  return (
    <Global
      styles={css`
        :root {
          // Anchor link smooth scroll
          scroll-behavior: smooth;
          // Create anchor link offset equal to fixed header height and spacing
          scroll-padding-top: calc(
            ${theme.sizes.headerHeight} + ${theme.spacing.xl}
          );
          ${allVars}

          @media ${minWidth('lg')} {
            ${sizesLgCss.join('')}
            ${spacingLg.join('')}
          }

          @media ${minWidth('xl')} {
            ${sizesXlCss.join('')}
          }
        }

        @media (prefers-reduced-motion) {
          --var-transition-ease-in: none;
        }

        body {
          font-family: ${theme.fonts.body};
          font-size: ${theme.fontSizes.md};
          line-height: ${theme.lineHeights.lg};
          background-color: ${theme.colors.background};
          color: ${theme.colors.text};
        }

        a {
          color: ${theme.colors.primary};
        }

        button {
          color: ${theme.colors.text};
        }

        p {
          margin-bottom: ${theme.spacing.md};
          letter-spacing: 0.02em;
        }

        span {
          letter-spacing: 0.02em;
        }

        select {
          letter-spacing: 0.02em;
          color: ${theme.colors.text};
        }

        option {
          color: initial;
        }
      `}
    />
  )
}

export default GlobalStyles
