import React, { SVGProps } from 'react'

const ArrowRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 16"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M1 8h16M10 1l7 7-7 7"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ArrowRightIcon
