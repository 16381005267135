import { TypedDocumentNode, gql, useApolloClient } from '@apollo/client'
import { useCartIdContext } from '@emico-hooks/cart-id'

import {
  RestoreCartMutation,
  RestoreCartMutationVariables,
} from './useRestoreCart.generated'

export const mutation = gql`
  mutation RestoreCart($maskedQuoteId: String, $maskedOrderId: String) {
    restoreCart(maskedOrderId: $maskedOrderId, maskedQuoteId: $maskedQuoteId)
  }
` as TypedDocumentNode<RestoreCartMutation, RestoreCartMutationVariables>

export function useRestoreCart() {
  const client = useApolloClient()
  const cartIdVar = useCartIdContext()

  return async (maskedQuoteId: string, maskedOrderId: string) => {
    const cartId = await client.mutate({
      mutation,
      errorPolicy: 'all',
      variables: {
        maskedOrderId,
        maskedQuoteId,
      },
    })

    if (!cartId.data?.restoreCart) {
      return false
    }

    cartIdVar(cartId.data.restoreCart)

    return cartId
  }
}
