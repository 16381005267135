import { stripMaybes } from '@emico-utils/graphql-data-utils'
import React from 'react'
import { Organization as OrganizationSchema } from 'schema-dts'

import { useStoreConfig } from '../lib/storeConfig'
import { useCraftGlobalSets } from '../lib/useCraftGlobalSets'
import SchemaJsonLd from './SchemaJsonLd'

type CleanOrganizationSchema = Exclude<OrganizationSchema, string>
type Props = Partial<CleanOrganizationSchema>

const SchemaOrganization = (other: Props) => {
  const { storeConfig } = useStoreConfig()
  const { data: globalSets } = useCraftGlobalSets()

  const contactGlobalSet = globalSets?.find(
    (globalSet) => globalSet?.__typename === 'CraftContactGlobalGlobalSet',
  )

  const contactData =
    contactGlobalSet?.__typename === 'CraftContactGlobalGlobalSet'
      ? contactGlobalSet.siteGlobalContact?.[0]
      : undefined

  const socialUrls = [
    contactData?.instagram?.url,
    contactData?.facebook?.url,
    contactData?.youtube?.url,
    contactData?.linkedin?.url,
  ].filter(stripMaybes)

  if (!storeConfig) {
    return null
  }

  return (
    <SchemaJsonLd<CleanOrganizationSchema>
      item={{
        '@context': 'https://schema.org',
        '@type': 'Organization',
        '@id': `${storeConfig.baseUrl}#Organization`,
        name: storeConfig.storeName ?? undefined,
        url: storeConfig.baseUrl ?? 'https://www.berg.com',
        sameAs: socialUrls,
        address: {
          '@type': 'PostalAddress',
          streetAddress: contactData?.addressStreet ?? undefined,
          postalCode: contactData?.addressPostalCode ?? undefined,
          addressLocality: contactData?.addressLocality ?? undefined,
        },
        email: contactData?.emailCustomerService ?? undefined,
        contactPoint: [
          {
            '@type': 'ContactPoint',
            contactType: 'Customer service',
            telephone: contactData?.telephoneCustomerService ?? undefined,
            email: contactData?.emailCustomerService ?? undefined,
          },
          {
            '@type': 'ContactPoint',
            contactType: 'Headquarters',
            telephone: contactData?.telephoneHeadquarters ?? undefined,
          },
        ],
        ...other,
      }}
    />
  )
}

export default SchemaOrganization
