import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import React, { ComponentProps } from 'react'

import { Maybe, Money } from '@emico/graphql-schema-types'
import { CurrencyDisplay, Price as BasePrice } from '@emico/price'

import theme from '../theme'

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Minus = styled.span`
  font-weight: ${theme.fontWeights.bold};
`

const CustomPrice = styled('span', {
  shouldForwardProp: (prop) => !['isLarge'].includes(prop.toString()),
})<{ isLarge?: boolean }>`
  font-size: ${({ isLarge }) =>
    isLarge
      ? `var(--price-font-size-large, ${theme.fontSizes['2xl']})`
      : `var(--price-font-size, ${theme.fontSizes.lg})`};
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;
`

const StyledPrice = styled(BasePrice.original, {
  shouldForwardProp: (prop) => !['isLarge'].includes(prop.toString()),
})<{ isLarge?: boolean }>`
  font-size: ${({ isLarge }) =>
    isLarge
      ? `var(--price-font-size-large, ${theme.fontSizes['2xl']})`
      : `var(--price-font-size, ${theme.fontSizes.lg})`};
  font-weight: ${theme.fontWeights.bold};
`

interface Props
  extends Omit<
    ComponentProps<typeof BasePrice>,
    'value' | 'currency' | 'decimalDash'
  > {
  price?: Maybe<Money>
  currencyDisplay?: CurrencyDisplay
  isLarge?: boolean
  isDiscount?: boolean
}

const Price = ({
  price,
  currencyDisplay = 'none',
  isLarge = false,
  isDiscount = false,
  ...other
}: Props) => {
  if (!price) {
    return null
  }

  if (price.value === 0) {
    return (
      <CustomPrice {...other}>
        <Trans>Free</Trans>
      </CustomPrice>
    )
  }

  if (isDiscount) {
    return (
      <PriceWrapper>
        {isDiscount && <Minus>&#8722;</Minus>}

        <StyledPrice
          value={price.value ?? 0}
          currency={price.currency ?? undefined}
          currencyDisplay={currencyDisplay}
          isLarge={isLarge}
          decimalDash
          {...other}
        />
      </PriceWrapper>
    )
  }

  return (
    <StyledPrice
      value={price.value ?? 0}
      currency={price.currency ?? undefined}
      currencyDisplay={currencyDisplay}
      isLarge={isLarge}
      decimalDash
      {...other}
    />
  )
}

export default Price
