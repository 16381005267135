import { StyledComponent } from '@emotion/styled'
import { ForwardRefExoticComponent, RefAttributes } from 'react'

import { Select as BaseSelect } from '@emico/input'

import Select from '../components/Select'

BaseSelect.override(
  Select as ForwardRefExoticComponent<RefAttributes<HTMLSelectElement>> &
    StyledComponent<RefAttributes<HTMLSelectElement>>,
)
