import { gql, TypedDocumentNode } from '@apollo/client'

import { CategoryFragment } from './categoryFragment.generated'

const categoryFragment = gql`
  fragment CategoryFragment on CategoryTree {
    # deprecated
    id
    uid
    tweakwiseItemNumber
    name
    includeInMenu
    displayMode
    isAnchor
    position
    canonicalUrl
    urlKey
    urlPath
    breadcrumbs {
      categoryUid
      categoryName
      categoryUrlKey
      categoryUrlPath
    }
  }
` as TypedDocumentNode<CategoryFragment, never>

export default categoryFragment
