import { stripMaybes } from '@emico-utils/graphql-data-utils'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'

import { useGetHrefLangLinks } from '../lib/useGetHrefLangLinks'

interface Props {
  metaTitle?: string
  metaDescription?: string
}

/**
 * Component to add meta data in <head>
 */
const Meta = ({ metaTitle, metaDescription }: Props) => {
  const { asPath } = useRouter()
  const hrefLangUri = asPath.startsWith('/') ? asPath.slice(1) : asPath

  const { data: hrefLangLinks } = useGetHrefLangLinks(hrefLangUri)

  return (
    <Head>
      {metaTitle && (
        <>
          <title>{metaTitle}</title>
          <meta property="og:title" content={metaTitle} />
        </>
      )}

      {metaDescription && <meta name="description" content={metaDescription} />}

      {hrefLangLinks?.length !== 0 &&
        hrefLangLinks
          ?.filter(stripMaybes)
          .map(
            (linkInfo) =>
              linkInfo.url &&
              linkInfo.language && (
                <link
                  key={linkInfo.url}
                  rel="alternate"
                  href={linkInfo.url}
                  hrefLang={linkInfo.language.toLowerCase()}
                />
              ),
          )}
    </Head>
  )
}

export default Meta
