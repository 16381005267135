import { RadioCustomVisibleButton as BaseRadioCustomVisibleButton } from '@emico-react/input-radio'
import styled from '@emotion/styled'

import theme from '../theme'

const StyledRadioCustomVisibleButton = styled(
  BaseRadioCustomVisibleButton.original,
)`
  --emico-radio-custom-visible-button-active-color: ${theme.colors.primary};
  --emico-radio-custom-visible-button-focus-color: ${theme.colors.primary};
  --emico-radio-custom-visible-button-active-color-disabled: ${theme.colors
    .grayMiddle};
  --emico-radio-custom-visible-button-border-disabled: 2px solid
    ${theme.colors.grayMiddle};
`

BaseRadioCustomVisibleButton.override(
  StyledRadioCustomVisibleButton as typeof BaseRadioCustomVisibleButton.original,
)
