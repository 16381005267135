import Head from 'next/head'
import React from 'react'

import useLocale from '../lib/useLocale'

/**
 * Component to add default og meta data in <head>
 */
const MetaOgDefaults = () => {
  const locale = useLocale()

  return (
    <Head>
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Bergtoys" />
      <meta property="og:locale" content={locale} />
    </Head>
  )
}

export default MetaOgDefaults
