import { setupStoreViews } from '@emico-utils/storeview-manager'

import { setStoreViews, StoreView } from '@emico/storeviews'

import storeConfig from '../storeConfig.json'

const mapUrl = (url: string) =>
  new URL(url, storeConfig.storeViews[0].basename).toString()

export const defaultStoreConfig =
  storeConfig.storeViews.find((storeView) => storeView.code === 'b2c_nl') ??
  storeConfig.storeViews[0]

setupStoreViews(storeConfig.storeViews, 'nl')

// Backward compatible
// Dependencies expect `@emico/storeviews` `setStoreViews`
const storeViewOld: StoreView = {
  ...defaultStoreConfig,
  basename: '/',
  location: /\//, // TODO: deprecate
  makeUrl: mapUrl,
  name: { message: 'Shop', id: 'shop' },
  default: true,
}

setStoreViews([storeViewOld], storeViewOld)

export {}
