import { useQuery, gql, TypedDocumentNode } from '@apollo/client'
import { getCacheableContext } from '@emico-utils/graphql-data-utils'

import {
  GetHrefLangLinksQuery,
  GetHrefLangLinksQueryVariables,
} from './useGetHrefLangLinks.generated'

export const getHrefLangLinksQuery = gql`
  query GetHrefLangLinksQuery($requestUri: String) {
    getHrefLangLinks(requestUri: $requestUri) {
      url
      language
    }
  }
` as TypedDocumentNode<GetHrefLangLinksQuery, GetHrefLangLinksQueryVariables>

/**
 * Query to get an array of href lang objects, with language info and storeview-specific URLs of the page in question.
 *
 * @returns array of object with href lang info
 *
 * Project usage example:
 *
 * ```
 * const Component = () => {
 *  const { data: hrefLangLinks } = useGetHrefLangLinks(uri)
 *
 *  [...]
 * }
 * ```
 */

export function useGetHrefLangLinks(requestUri: string) {
  const { data, error, loading, ...others } = useQuery(getHrefLangLinksQuery, {
    variables: {
      requestUri,
    },
    context: getCacheableContext(),
  })

  return {
    loading,
    data: data?.getHrefLangLinks ?? undefined,
    error,
    ...others,
  }
}
