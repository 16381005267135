import React, { SVGProps } from 'react'

const ShoppingCartIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 20"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M7.225 16.93c-.846 0-1.535.69-1.535 1.535 0 .846.689 1.535 1.535 1.535s1.535-.689 1.535-1.535-.689-1.534-1.535-1.534zM17.113 16.93c-.846 0-1.535.69-1.535 1.535 0 .846.689 1.535 1.535 1.535s1.535-.689 1.535-1.535-.689-1.534-1.535-1.534zM20.564 3.824a.818.818 0 00-.647-.315H4.338L3.75.655A.816.816 0 002.953 0H0v1.634h2.281L5.044 14.99a.816.816 0 00.796.655h12.808v-1.634H6.504l-.54-2.638H18.4a.817.817 0 00.796-.622l1.518-6.23a.84.84 0 00-.149-.697zm-1.684 1.32l-1.12 4.595H5.624L4.68 5.143H18.88z"
      fill="currentColor"
    />
  </svg>
)

export default ShoppingCartIcon
