import { t } from '@lingui/macro'

interface Message {
  [key: string]: string
}

export function getDefaultErrorMessage(type: string) {
  const errorMessages: Message = {
    required: t`This field is required`,
    validate: t`This field has an incorrect input`,
    pattern: t`This field is incorrectly formatted.`,
  }

  return errorMessages[type]
}

export function getEmailErrorMessages(type: string) {
  const errorMessages: Message = {
    required: t`Enter an email address`,
    validate: t`This field has an incorrect input`,
    pattern: t`Enter an email address in the correct format. For example: name@example.com`,
  }

  return errorMessages[type]
}
