import React, { SVGProps } from 'react'

const CrossIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 17"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M1.39 1.39l14.22 14.22m0-14.22L1.39 15.61 15.61 1.39z"
      stroke="currentColor"
      strokeWidth={2.344}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CrossIcon
