import styled from '@emotion/styled'

import { Loader as BaseLoader } from '@emico/ui'

import theme from '../theme'

const StyledLoader = styled(BaseLoader.original)`
  border-color: ${theme.colors.primary};
  border-left-color: transparent;
`

BaseLoader.override(StyledLoader as typeof BaseLoader.original)
