import { t } from '@lingui/macro'

export default function getSeoPageTitle(
  pageTitle?: string,
  pageTitleType: 'default' | 'brand' | 'website' = 'default',
) {
  let defaultTitle

  switch (pageTitleType) {
    case 'website':
      defaultTitle = t({
        message: 'berg.com',
      })
      break
    case 'brand':
      defaultTitle = t({
        message: 'BERG',
      })
      break
    default:
      defaultTitle = t({
        message:
          'Premium pedal go-karts, trampolines, ride-ons and playsets | berg.com',
      })
  }

  return pageTitle ? `${pageTitle} | ${defaultTitle}` : defaultTitle
}
