import styled from '@emotion/styled'

import theme from '../theme'

const Container = styled('div', {
  shouldForwardProp: (prop) =>
    !['noPadding', 'isLarge'].includes(prop.toString()),
})<{
  noPadding?: boolean
  isLarge?: boolean
}>`
  margin: 0 auto;
  padding: ${({ noPadding }) => !noPadding && `0 ${theme.containerPadding}`};
  max-width: ${({ isLarge }) =>
    isLarge ? theme.sizes.containerXlWidth : theme.sizes.containerWidth};
`

export default Container
