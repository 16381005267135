import React, { SVGProps } from 'react'

const CheckmarkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 14"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M1 7l6 6L17 1"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CheckmarkIcon
