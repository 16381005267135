import React, { SVGProps } from 'react'

const ChevronLeftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 8 14"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M6.044.002a.998.998 0 01.768 1.636l-4.47 5.348 4.31 5.358a.998.998 0 01-.15 1.407.999.999 0 01-1.456-.15L.226 7.616a.998.998 0 010-1.267L5.217.36a.998.998 0 01.828-.36z"
      fill="currentColor"
    />
  </svg>
)

export default ChevronLeftIcon
