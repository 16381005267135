import { css } from '@emotion/react'
import transitions from './transitions'

/**
 * Assign zoomNestedImage to image's parent
 * Images within will zoom on parent hover
 *
 * @param scale : animated image scale
 * @param duration : animation duration
 * @returns css instance
 */

const zoomNestedImage = (
  scale: number = 1.1,
  duration: string = transitions.durations.slow,
) => css`
  overflow: hidden;
  position: relative;

  img {
    transition-property: ${transitions.properties.common};
    transition-duration: ${duration};
    transition-timing-function: ${transitions.timingFunctions
      .cubicBezierSmooth};
  }

  &:hover {
    img {
      transform: scale(${scale});
    }
  }
`

/**
 * Assign linkHover to interactive element to add an animated line underneath on hover
 * Line will animate from 0 to 1 scaleX
 *
 * @param color animated line color
 * @param height animated line height
 * @returns css instance
 */

const borderStyling = css`
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: scaleX(0);
  transform-origin: right;
  transition-property: ${transitions.properties.common};
  transition-duration: ${transitions.durations.slow};
  transition-timing-function: ${transitions.timingFunctions.cubicBezierSmooth};
`

const linkHover = (
  color: string = 'currentColor',
  height: string = '1px',
  nestedElement?: 'h2' | 'h3' | 'span' | 'div',
) =>
  nestedElement
    ? css`
        ${nestedElement} {
          position: relative;

          &::before {
            ${borderStyling};
            height: ${height};
            background: ${color};
          }
        }

        &:hover {
          ${nestedElement} {
            &::before {
              transform: scaleX(1);
              transform-origin: left;
            }
          }
        }
      `
    : css`
        position: relative;

        &::before {
          ${borderStyling};
          height: ${height};
          background: ${color};
        }

        &:hover {
          &::before {
            transform: scaleX(1);
            transform-origin: left;
          }
        }
      `

export default {
  zoomNestedImage,
  linkHover,
}
