import React, { SVGProps } from 'react'

const ArrowLeftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 16"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M17 8H1M8 1L1 8l7 7"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ArrowLeftIcon
