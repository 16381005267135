// Implementation of next.js/examples/with-apollo with support for different
// Magento storeViews

import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { fragmentTypes } from '@emico-hooks/graphql-schema-types'
import { globalWindow } from '@emico-utils/ssr-utils'
import { parse } from 'cookie'
import { sha256 } from 'crypto-hash'
import merge from 'deepmerge'
import { JWTPayload, decodeJwt } from 'jose'
import isEqual from 'lodash.isequal'
import { GetStaticPropsResult } from 'next'
import { useMemo } from 'react'

import useLocale from '../lib/useLocale'
import storeConfigs from '../storeConfig.json'
import { cartErrorLink } from './cartErrorLink'
import dataIdFromObject from './dataIdFromObject'
import typePolicies from './typePolicies'

const graphqlUri = process.env.REACT_APP_APOLLO_GRAPHQL_URL ?? '/graphql'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let refreshTokenPromise: Promise<{
  token?: string
  done?: boolean
}> | null = null

export function createApolloClient(
  storeCode: string,
  locale: string,
  storeId?: number,
) {
  const headers: Record<string, unknown> = {
    Store: storeCode,
  }

  const link = createPersistedQueryLink({
    sha256,
    useGETForHashedQueries: true,
  }).concat(new HttpLink({ uri: graphqlUri, headers }))

  const cache = new InMemoryCache({
    dataIdFromObject,
    typePolicies,
    possibleTypes: fragmentTypes.possibleTypes,
  })

  const jwtCheck = setContext(async (request, context) => {
    if (!context?.headers?.customerToken) {
      return {}
    }

    // const currentToken = context?.customerToken?.token
    const currentToken = parse(globalWindow?.document.cookie ?? '')[
      'customer-token'
    ]

    if (!currentToken) {
      return {
        headers: {
          'x-token-issues': 'no-token-available',
        },
      }
    }

    let claims: JWTPayload | null = null

    try {
      claims = decodeJwt(currentToken)
    } catch {
      // Failed to decode the JWT, will try to refresh the token anyway
    }

    const now = (Date.now() / 1000) | 0

    if (claims && claims.exp && claims.exp > now) {
      return { headers: { customerToken: currentToken } }
    }

    // Refresh the token by calling the /api/refresh-token endpoint
    const headers = new Headers()

    headers.append('Content-Type', 'application/json')

    if (!refreshTokenPromise) {
      refreshTokenPromise = fetch('/api/refresh-token', {
        headers,
        method: 'POST',
        body: JSON.stringify({
          store: storeCode,
        }),
      })
        .then((e) => e.json())
        .catch(() => {
          // no-op
          // If no token is returned, we will not set the customerToken header
        })
    }

    const response = await refreshTokenPromise
    const token = response?.token

    refreshTokenPromise = null

    if (!token) {
      return {
        headers: {
          'x-token-issues': 'refresh-failed',
        },
      }
    }

    return { headers: { customerToken: token } }
  })

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, stack }) => {
        console.error(
          `GraphQL error in operation ${operation.operationName}:`,
          storeCode,
          operation.variables,
          message,
        )
      })
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`)
    }
  })

  const headersLink = new ApolloLink((operation, forward) => forward(operation))

  return new ApolloClient({
    ssrMode: typeof globalWindow === 'undefined',
    link: headersLink
      .concat(jwtCheck)
      .concat(errorLink)
      .concat(cartErrorLink(locale, storeId))
      .concat(link),
    cache,
  })
}

const apolloClientMap: Record<string, ApolloClient<NormalizedCacheObject>> = {}

export function initializeApollo(
  locale: string,
  initialState: NormalizedCacheObject | null = null,
) {
  const storeConfig = storeConfigs.storeViews.find((o) => o.locale === locale)

  const _apolloClient =
    apolloClientMap[locale] ??
    createApolloClient(
      storeConfig?.code ?? 'default',
      locale,
      storeConfig?.storeId,
    )

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    const existingCache = _apolloClient.extract()

    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    })

    _apolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof globalWindow === 'undefined') {
    return _apolloClient
  }
  // Create the Apollo Client once in the client
  if (!apolloClientMap[locale]) {
    apolloClientMap[locale] = _apolloClient
  }

  return _apolloClient
}

type PageProps<P> = GetStaticPropsResult<P> & {
  props: {
    [APOLLO_STATE_PROP_NAME]?: NormalizedCacheObject | null | undefined
  }
}

export function addApolloState<P>(
  client: ApolloClient<NormalizedCacheObject | null | undefined>,
  pageProps: PageProps<P>,
) {
  if (pageProps.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

export function useApollo<P>(pageProps: PageProps<P>['props']) {
  const state = pageProps?.[APOLLO_STATE_PROP_NAME]
  const locale = useLocale()

  return useMemo(() => initializeApollo(locale, state), [state, locale])
}
