import { Observable } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { getCustomerTokenHeaderObject } from '@emico-hooks/login-token'
import { globalWindow } from '@emico-utils/ssr-utils'

import { mutation } from '../lib/useRestoreCart'
import { initializeApollo } from './apolloClient'

const cartErrorLink = (locale: string, storeId?: number) =>
  onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      const graphqlError = graphQLErrors.find((error) => {
        if (
          error.path?.[0] === 'cart' &&
          error.extensions?.category === 'graphql-no-such-entity'
        ) {
          return true
        }

        return false
      })

      if (graphqlError) {
        return new Observable((subscriber) => {
          restoreCartOnError(locale, storeId).then(
            (value) => {
              if (subscriber.closed) {
                return
              }
              operation.setContext(getCustomerTokenHeaderObject(value))
              subscriber.next(value)
              subscriber.complete()
            },
            (err) => subscriber.error(err),
          )
        }).flatMap(() => forward(operation))
      }
    }

    return
  })

async function restoreCartOnError(locale: string, storeId?: number) {
  if (globalWindow) {
    // Triggering an event so this restoration can be canceled on e.g. the order success page.
    const notPrevented = globalWindow.dispatchEvent(
      new Event('restore-cart-on-error'),
    )

    if (!notPrevented) {
      return
    }
  }

  const client = initializeApollo(locale)

  const maskedQuoteId = localStorage.getItem('@emico/pre-order-cart-id')
  const maskedOrderId = localStorage.getItem('@emico/pre-order-order-id')

  if (maskedOrderId && maskedQuoteId) {
    // If both masked order id and masked quote id is set, we can restore the cart to its previous
    // state.

    const cartId = await client.mutate({
      mutation,
      errorPolicy: 'all',
      variables: {
        maskedOrderId,
        maskedQuoteId,
      },
    })

    // If the query did succeed, we can restore the cart.
    if (cartId.data?.restoreCart) {
      // TODO: Integrate this better with the var system
      localStorage.setItem(`@emico/${storeId}/cart-id`, cartId.data.restoreCart)

      return cartId.data.restoreCart
    }
  }

  // TODO: Integrate this better with the var system
  localStorage.removeItem(`${storeId}/cart-id`)
  // window.location.reload()

  return null
}

export { cartErrorLink }
